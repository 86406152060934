<template>
  <div>
    <div v-if="showCurrentRk == rkItems[0]">
      <div class="xsayt">
        <!-- Header start -->
        <header class="header">
          <div class="centered">
            <div class="header-top">
              <div class="logo">
                <a href="/"></a>
              </div>
              <div class="a-top" v-if="showCurrentRk == rkItems[6]">
                <div class="a-st a-display w-728x90">
                  <span class="a-text">728x90</span>
                  <div class="smrt-info">
                    <i class="smrt-l"></i>
                    <div class="smrt-wrap">
                      <div class="smrt-back"></div>
                      <div class="smrt-md">
                        <div class="smrt-m">
                          Ads by <img src="@/assets/images/smt.png" />
                        </div>
                        <div class="smrt-buttons">
                          <div class="smrt-button">
                            <a
                              class="smrt1"
                              href="https://advertiser.smartbee.az/az/register/"
                              target="_blank"
                              >Reklamçı ol</a
                            >
                          </div>
                          <div class="smrt-button">
                            <a
                              class="smrt2"
                              href="https://advertiser.smartbee.az/az/register/"
                              target="_blank"
                              >Yayınçı ol</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="smrt-text">
                        Reklamınızı <b>SmartBee</b> ilə effektiv edin.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="header-m">
              <div class="top-menu">
                <ul>
                  <li>
                    <a href="/">Ana səhifə</a>
                  </li>
                  <li>
                    <a href="/">Dünya</a>
                  </li>
                  <li>
                    <a href="/">Texnologiya</a>
                  </li>
                  <li>
                    <a href="/">Sosial Media</a>
                  </li>
                  <li>
                    <a href="/">Dizayn</a>
                  </li>
                  <li>
                    <a href="/">Heyvanlar</a>
                  </li>
                  <li>
                    <a href="/">İdman</a>
                  </li>
                  <li>
                    <a href="/">Marketinq</a>
                  </li>
                  <li>
                    <a href="/">Kino</a>
                  </li>
                </ul>
              </div>
              <div class="search"></div>
            </div>
          </div>
        </header>
        <!-- Header end -->

        <div class="container">
          <div class="centered">
            <div class="content">
              <div class="site-left">
                <div class="full-post">
                  <div class="f-video" v-if="showCurrentRk == rkItems[0]">
                    <div class="pre-roll">
                      <video
                        id="pre-roll"
                        width="620"
                        height="350"
                        muted=""
                        autoplay=""
                      >
                        <source
                          src="@/assets/videos/pre-roll.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <div id="skip" class="skip" @click="closevid">skip</div>
                    </div>
                  </div>

                  <h2 class="full-post-title">Bakı şəhərinə yüksəkdən baxış</h2>
                  <div class="full-post-extra">
                    <span>Dünya</span>
                    <span>20 İyul 2020</span>
                  </div>
                  <div class="full-post-article"></div>
                  <div class="tags">
                    <div class="t-text">Taglar:</div>
                    <div class="tag-list">
                      <ul>
                        <li>
                          <a href="/">bədii</a>
                        </li>
                        <li>
                          <a href="/">ədəbiyyat</a>
                        </li>
                        <li>
                          <a href="/">insan</a>
                        </li>
                        <li>
                          <a href="/">tarix</a>
                        </li>
                        <li>
                          <a href="/">dünya</a>
                        </li>
                        <li>
                          <a href="/">obraz</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="related-posts">
                    <h2 class="r-title">Oxşar məqalələr</h2>
                    <!-- Posts start -->
                    <div class="posts v-video v-related">
                      <div class="posts-r">
                        <div class="post">
                          <a href="/">
                            <div class="post-image">
                              <img
                                src="https://beta.smartbee.az/images/p/post17.jpg"
                              />
                              <i></i>
                              <div class="v-time">02:45</div>
                            </div>
                            <div class="post-info">
                              <h2 class="post-title">
                                Qırmızı ferrarilər Roma küçələrində yürüş
                                keçirdi (VİDEO)
                              </h2>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="posts-r">
                        <div class="post">
                          <a href="/">
                            <div class="post-image">
                              <img
                                src="https://beta.smartbee.az/images/p/post18.jpg"
                              />
                              <i></i>
                              <div class="v-time">03:16</div>
                            </div>
                            <div class="post-info">
                              <h2 class="post-title">
                                Narazı Belarus restorator Lukaşenkonu rinqə
                                çağırdı və vaxt verdi
                              </h2>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="posts-r">
                        <div class="post">
                          <a href="/">
                            <div class="post-image">
                              <img
                                src="https://beta.smartbee.az/images/p/post19.jpg"
                              />
                              <i></i>
                              <div class="v-time">04:10</div>
                            </div>
                            <div class="post-info">
                              <h2 class="post-title">
                                Çindən Türkiyəyə konteyner qatarları daha tez
                                çatacaq
                              </h2>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="posts-r">
                        <div class="post">
                          <a href="/">
                            <div class="post-image">
                              <img
                                src="https://beta.smartbee.az/images/p/post20.jpg"
                              />
                              <i></i>
                              <div class="v-time">02:13</div>
                            </div>
                            <div class="post-info">
                              <h2 class="post-title">
                                Tutuquşunun hafizəyə görə insandan üstün olduğu
                                bilindi
                              </h2>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- Posts end -->
                  </div>
                </div>
              </div>
              <div class="sidebar">
                <div class="s-tp"></div>
                <!-- Posts start -->
                <div class="posts v-video">
                  <div class="posts-r">
                    <div class="post">
                      <a href="/">
                        <div class="post-image">
                          <img
                            src="https://beta.smartbee.az/images/p/post12.jpg"
                          />
                          <i></i>
                          <div class="v-time">02:45</div>
                        </div>
                        <div class="post-info">
                          <h2 class="post-title">
                            Kalibrilərin başqa quşlardan gizlin “rabitə kanalı”
                            tapılıb
                          </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="posts-r">
                    <div class="post">
                      <a href="/">
                        <div class="post-image">
                          <img
                            src="https://beta.smartbee.az/images/p/post13.jpg"
                          />
                          <i></i>
                          <div class="v-time">03:16</div>
                        </div>
                        <div class="post-info">
                          <h2 class="post-title">
                            Rusiya XİN: "Bu situasiya regionun təhlükəsizliyinə
                            də təsir edə bilər"
                          </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="posts-r">
                    <div class="post">
                      <a href="/">
                        <div class="post-image">
                          <img
                            src="https://beta.smartbee.az/images/p/post14.jpg"
                          />
                          <i></i>
                          <div class="v-time">04:10</div>
                        </div>
                        <div class="post-info">
                          <h2 class="post-title">
                            Olimpiada üçün güləş yarışlarının vaxtı açıqlanıb
                          </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="posts-r">
                    <div class="post">
                      <a href="/">
                        <div class="post-image">
                          <img
                            src="https://beta.smartbee.az/images/p/post15.jpg"
                          />
                          <i></i>
                          <div class="v-time">02:13</div>
                        </div>
                        <div class="post-info">
                          <h2 class="post-title">
                            UEFA prezidenti: "Çeferin Riskə gedilməyəcək"
                          </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="posts-r">
                    <div class="post">
                      <a href="/">
                        <div class="post-image">
                          <img
                            src="https://beta.smartbee.az/images/p/post16.jpg"
                          />
                          <i></i>
                          <div class="v-time">03:25</div>
                        </div>
                        <div class="post-info">
                          <h2 class="post-title">
                            Gürcüstanda COVİD-19-a yoluxma hallarında artım var
                          </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- Posts end -->
              </div>
            </div>
          </div>
        </div>

        <footer class="footer">
          <div class="centered">
            <div class="footer-wrap">
              © Smartbee 2005 - 2020. All images © Flickr.com
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div v-else>
      <div v-if="showCurrentRk == rkItems[4]">
        <div class="a-st b-left b-fixed w-160x600">
          <span class="a-text">160x600</span>
        </div>
        <div class="a-st b-right b-fixed w-160x600">
          <span class="a-text">160x600</span>
        </div>
      </div>
      <div class="centered">
        <div class="a-st w-940x120" v-if="showCurrentRk == rkItems[9]">
          <span class="a-text v2">
            EXPANDABLE BANNER
            <p class="hover-before">Default view, before expansion</p>
            <p class="hover-after">On hover, after expansion</p>
          </span>
        </div>
      </div>
      <!-- Header start -->
      <header class="header">
        <div class="centered">
          <div class="header-top">
            <div class="logo">
              <a href="/"></a>
            </div>
            <div class="a-top" v-if="showCurrentRk == rkItems[6]">
              <div class="a-st a-display w-728x90">
                <span class="a-text">728x90</span>
                <div class="smrt-info">
                  <i class="smrt-l"></i>
                  <div class="smrt-wrap">
                    <div class="smrt-back"></div>
                    <div class="smrt-md">
                      <div class="smrt-m">
                        Ads by <img src="@/assets/images/smt.png" />
                      </div>
                      <div class="smrt-buttons">
                        <div class="smrt-button">
                          <a
                            class="smrt1"
                            href="https://advertiser.smartbee.az/az/register/"
                            target="_blank"
                            >Reklamçı ol</a
                          >
                        </div>
                        <div class="smrt-button">
                          <a
                            class="smrt2"
                            href="https://advertiser.smartbee.az/az/register/"
                            target="_blank"
                            >Yayınçı ol</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="smrt-text">
                      Reklamınızı <b>SmartBee</b> ilə effektiv edin.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header-m">
            <div class="top-menu">
              <ul>
                <li>
                  <a href="/">Ana səhifə</a>
                </li>
                <li>
                  <a href="/">Dünya</a>
                </li>
                <li>
                  <a href="/">Texnologiya</a>
                </li>
                <li>
                  <a href="/">Sosial Media</a>
                </li>
                <li>
                  <a href="/">Dizayn</a>
                </li>
                <li>
                  <a href="/">Heyvanlar</a>
                </li>
                <li>
                  <a href="/">İdman</a>
                </li>
                <li>
                  <a href="/">Marketinq</a>
                </li>
                <li>
                  <a href="/">Kino</a>
                </li>
              </ul>
            </div>
            <div class="search"></div>
          </div>
        </div>
      </header>
      <div class="b-fixed b-left" v-if="showCurrentRk == rkItems[6]">
        <div class="a-st a-display w-160x600">
          <span class="a-text">160x600</span>
          <div class="smrt-info">
            <i class="smrt-l"></i>
            <div class="smrt-wrap">
              <div class="smrt-back"></div>
              <div class="smrt-md">
                <div class="smrt-m">
                  Ads by <img src="@/assets/images/smt.png" />
                </div>
                <div class="smrt-buttons">
                  <div class="smrt-button">
                    <a
                      class="smrt1"
                      href="https://advertiser.smartbee.az/az/register/"
                      target="_blank"
                      >Reklamçı ol</a
                    >
                  </div>
                  <div class="smrt-button">
                    <a
                      class="smrt2"
                      href="https://advertiser.smartbee.az/az/register/"
                      target="_blank"
                      >Yayınçı ol</a
                    >
                  </div>
                </div>
              </div>
              <div class="smrt-text">
                Reklamınızı <b>SmartBee</b> ilə effektiv edin.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="b-fixed b-right" v-if="showCurrentRk == rkItems[6]">
        <div class="a-st a-display w-120x600">
          <span class="a-text">120x600</span>
          <div class="smrt-info">
            <i class="smrt-l"></i>
            <div class="smrt-wrap">
              <div class="smrt-back"></div>
              <div class="smrt-md">
                <div class="smrt-m">
                  Ads by <img src="@/assets/images/smt.png" />
                </div>
                <div class="smrt-buttons">
                  <div class="smrt-button">
                    <a
                      class="smrt1"
                      href="https://advertiser.smartbee.az/az/register/"
                      target="_blank"
                      >Reklamçı ol</a
                    >
                  </div>
                  <div class="smrt-button">
                    <a
                      class="smrt2"
                      href="https://advertiser.smartbee.az/az/register/"
                      target="_blank"
                      >Yayınçı ol</a
                    >
                  </div>
                </div>
              </div>
              <div class="smrt-text">
                Reklamınızı <b>SmartBee</b> ilə effektiv edin.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Header end -->
      <div class="container">
        <div class="centered">
          <div class="content">
            <div class="site-left">
              <!-- Top post start -->
              <div class="top-post">
                <div class="top-post-item">
                  <a href="/">
                    <div class="top-post-image">
                      <img src="@/assets/images/p/post1.jpg" />
                    </div>
                    <div class="top-post-info">
                      <h2 class="top-post-title">
                        Çernobıl faciəsi haqda bilmədiklərimiz
                      </h2>
                      <div class="top-post-text">
                        ABŞ və Britaniyanın ortaq istehsalı “Çernobıl” seriyalı
                        son günlər bütün dünyanın əsas gündəmini zəbt edib. Cəmi
                        5 seriyadan ibarət olan “Çernobıl” reytinq rekordları
                        qıraraq...
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <!-- Top post end -->
              <div class="full-post">
                <div
                  class="a-st w-620x440 mb-20"
                  v-if="showCurrentRk == rkItems[10]"
                >
                  <div id="inscroll-banner" style="width: 620px">
                    <div
                      class="inscroll-banner-item"
                      style="width: 620px; height: 443px"
                    >
                      <div class="scroll-x">
                        <div id="insb" class="interscroller">
                          <div
                            class="inb"
                            style="
                              width: 620px;
                              height: 423px;
                              left: 481.5px;
                              top: 0px !ipmortant;
                            "
                          >
                            <div
                              class="inr"
                              style="width: 620px; height: 1042px"
                            >
                              <img src="@/assets/images/p/scroll.jpg" alt="" />
                              <!-- <div
                              class="scroll-image"
                              style="
                                background-image: url(@/assets/images/p/scroll.jpg);
                              "
                            ></div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="scroll-text">Samsung Azərbaycan</div>
                  </div>
                </div>

                <div class="full-post-image">
                  <img src="@/assets/images/p/post8.jpg/" />
                </div>
                <h2 class="full-post-title">
                  Bədii ədəbiyyatda insan konsepsiyası
                </h2>
                <div class="full-post-extra">
                  <span>Ədəbiyyat</span>
                  <span>20 İyul 2020</span>
                </div>
                <div class="full-post-article">
                  <p>
                    Ədəbiyyatın mərkəzi məsələlərindən biri və ən başlıcası
                    insan problemidir. Bəşəriyyət bütün tarixi dövrlərdə maddi
                    aləmi dərk etməklə yanaşı, həmişə özünü də dərk etməyə
                    çalışmışdır. Özünüdərketmə prosesi dünyanın dərk
                    edilməsindən daha çətin olmuşdur. İnsanı dərk etməyə, onun
                    daxili sirlərini açmağa xidmət edən təfəkkür formalarından
                    ən güclüsü və ən uyğunu bədii ədəbiyyatdır. Ədəbiyyatın gücü
                    və üstünlüyü insanı daha adekvat şəkildə əks etdirə
                    bilməsidir. Əgər elm insanı dərk edərkən öz obyektini
                    sxematikləşdirirsə, ədəbiyyat obrazlar vasitəsilə insanı
                    real həyatdakına yaxın bir şəkildə qavrayır.
                  </p>
                  <div class="inpage" v-if="showCurrentRk == rkItems[1]">
                    <video id="inpage" width="620" height="350" muted autoplay>
                      <source
                        src="@/assets/videos/inpage.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="s-block" v-if="showCurrentRk == rkItems[6]">
                    <div class="a-st a-display w-468x60">
                      <span class="a-text">468x60</span>
                      <div class="smrt-info">
                        <i class="smrt-l"></i>
                        <div class="smrt-wrap">
                          <div class="smrt-back"></div>
                          <div class="smrt-md">
                            <div class="smrt-m">
                              Ads by
                              <img src="@/assets/images/smt.png" />
                            </div>
                            <div class="smrt-buttons">
                              <div class="smrt-button">
                                <a
                                  class="smrt1"
                                  href="https://advertiser.smartbee.az/az/register/"
                                  target="_blank"
                                  >Reklamçı ol</a
                                >
                              </div>
                              <div class="smrt-button">
                                <a
                                  class="smrt2"
                                  href="https://advertiser.smartbee.az/az/register/"
                                  target="_blank"
                                  >Yayınçı ol</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="smrt-text">
                            Reklamınızı <b>SmartBee</b> ilə effektiv edin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="posts-r half">
                    <div class="posts-wrap">
                      <div class="post with-image">
                        <a href="/">
                          <div class="post-image">
                            <img src="@/assets/images/p/post2.jpg" />
                          </div>
                          <div class="post-info">
                            <h2 class="post-title">
                              Yaşamaq gözəl şeydir, qardaşım
                            </h2>
                            <div class="post-text">
                              XX əsr dünya ədəbiyyatının ən böyük şairlərindən
                              olan Nazim Hikmət təkcə türk xalqının deyil, bütün
                              Şərq xalqlarının poeziyasına misilsiz təsir
                              göstərib, şeirdə yeni yollar açmış novator
                              sənətkar, əsrin klassikidir. O, özünün romantik
                              ruhlu, coşqun pafoslu, dərin məzmunlu əsərləri ilə
                              müasir türk ədəbiyyatının inkişafına böyük təkan
                              verib.
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="post with-image">
                        <a href="/">
                          <div class="post-image">
                            <img src="@/assets/images/p/post3.jpg" />
                          </div>
                          <div class="post-info">
                            <h2 class="post-title">
                              Ədəbiyyatımızın Mirzə Fətəli əsri
                            </h2>
                            <div class="post-text">
                              XIX əsr Azərbaycan ictimai fikrinin görkəmli
                              şəxsiyyətlərindən olan filosof, ictimai xadim
                              Mirzə Fətəli Axundzadə özünün maarifçilik
                              fəaliyyəti, çoxçeşidli yaradıcılığı ilə dövrünün
                              parlaq simalarından olmuşdur. O, həmçinin
                              Azərbaycan dramaturgiyasının, teatrın və ədəbi
                              tənqidin banisidir.
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      class="a-st n-block"
                      style="background: #fff"
                      v-if="showCurrentRk == rkItems[17]"
                    >
                      <div class="n-block-wrap">
                        <iframe
                          style="height: 410px; width: 100%"
                          src="https://homdom.az/banner-page?offer_type=2&property_type_id=1&min_area=&max_area=&min_price=&max_price=&flat_floor%5Bmin%5D=&flat_floor%5Bmax%5D=&floors_total_min=&floors_total_max=&built_year_min=&built_year_max=&block_title=Kiray%C9%99+m%C9%99nzil+elanlar%C4%B1&target_url=https%3A%2F%2Fhomdom.az%2Foffers%2Fkiraye%2Fmenzil&offer_count=10&title_color=%23000000&title_bg_color=%23ffffff&offer_title_color=%23000000&offer_title_bg_color=%23f1e8e8"
                        ></iframe>
                      </div>
                    </div>
                    <div
                      class="a-st w-620x300 mb-20"
                      style="height: 300px; cursor: pointer"
                      v-if="showCurrentRk == rkItems[5]"
                      @mouseenter="mousEnter"
                    >
                      <span class="a-text v2">
                        COLLAPSIBLE BANNER
                        <p class="hover-before">
                          Default view, before collapse
                        </p>
                        <p class="hover-after">On hover, after collapse</p>
                      </span>
                    </div>
                  </div>
                  <p>
                    Dünya ədəbiyyatını vahid bir proses, onun ayrı-ayrı
                    dövrlərini bu prosesin mərhələləri kimi qəbul etsək, onda
                    ədəbiyyatı bəşəriyyətin mənəvi-ruhi tərcümeyi-halı
                    adlandırmaq olar. Hər bir fərd uşaqlıqdan qocalığa doğru bir
                    inkişaf yolu keçdiyi kimi tarixi insan da yarandığı dövrdən
                    müasir zamana qədər təkamül yolu keçir. Bu uzun yolun
                    xüsusiyyətlərini, bu mürəkkəb inkişafın izlərini ədəbiyyatda
                    axtarmaq və tapmaq vacib məsələlərdən biridir. Məhz bədii
                    ədəbiyyat insanın tarixi inkişaf yolunu, onun təkamülünü
                    daha dolğun əks etdirən təfəkkür formasıdır.Bu tarixi yolu
                    bir neçə böyük mərhələyə bölmək olar. Onlardan birincisi və
                    ən qədimi mifoloji təfəkkür dövrüdür. Bu dövrün insanı
                    dünyanı rəmzlər aləmi kimi görür və reallıqda təxəyyül
                    arasında bir fərq qoymurdu. Mifoloji təfəkkür hələ tarixin
                    və zamanın nə olduğunu dərk etməmişdi. Qədim insanlar üçün
                    nə keçmiş, nə də gələcək vardı. Onlar üçün yalnız indiki
                    zaman içində yaşadığı an mövcud idi. Bədii təfəkkürdə hərfi
                    və məcazi məna anlayışları da yox idi. Arzu onlara reallıq
                    qədər gerçək, reallıq isə arzu qədər ecazkar görünürdü.
                    Antik sənətdə və ədəbiyyatda təsvir olunan insan obrazının
                    xarakterik cəhətini fiziki və ruhi mahiyyətlərin vəhdətində
                    və harmoniyasında axtarmaq lazımdır. Antik insan ən çox
                    təbii hisslər və həyəcanlarla yaşayan varlıqdır. Lakin bu, o
                    demək deyildir ki, klassik sənətdə ruhi aləm və onun əksi
                    yoxdur. Burada ruhi mahiyyət fiziki mahiyyətlə elə birləşir
                    ki, onları bir-birindən ayrı təsəvvür etmək mümkün deyil.
                  </p>
                </div>
                <div class="tags">
                  <div class="t-text">Taglar:</div>
                  <div class="tag-list">
                    <ul>
                      <li>
                        <a href="/">bədii</a>
                      </li>
                      <li>
                        <a href="/">ədəbiyyat</a>
                      </li>
                      <li>
                        <a href="/">insan</a>
                      </li>
                      <li>
                        <a href="/">tarix</a>
                      </li>
                      <li>
                        <a href="/">dünya</a>
                      </li>
                      <li>
                        <a href="/">obraz</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="a-st w-620x80 n-block"
                  v-if="showCurrentRk == rkItems[8]"
                >
                  <div class="n-block-wrap">
                    <div class="n-block-item">
                      <a href="/">
                        <div class="n-block-image">
                          <img src="@/assets/images/p/post9.jpg" />
                        </div>
                        <div class="n-block-info">
                          <h2 class="n-block-title">
                            ABŞ-da güclü zəlzələ: sunami xəbərdarlığı edildi
                          </h2>
                        </div>
                      </a>
                    </div>
                    <div class="n-block-item">
                      <a href="/">
                        <div class="n-block-image">
                          <img src="@/assets/images/p/post10.jpg" />
                        </div>
                        <div class="n-block-info">
                          <h2 class="n-block-title">
                            Mehriban Əliyeva jurnalistləri təbrik etdi
                          </h2>
                        </div>
                      </a>
                    </div>
                    <div class="n-block-item">
                      <a href="/">
                        <div class="n-block-image">
                          <img src="@/assets/images/p/post11.jpg" />
                        </div>
                        <div class="n-block-info">
                          <h2 class="n-block-title">
                            ABŞ Konqresində Azərbaycanın xeyrinə iki düzəliş
                          </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="related-posts">
                  <h2 class="r-title">Oxşar məqalələr</h2>
                  <!-- Posts start -->
                  <div class="posts sd">
                    <div class="posts-r">
                      <div class="post">
                        <a href="/">
                          <div class="post-info">
                            <h2 class="post-title">
                              Məlik Dadaşov - mənfi tiplərin mahir ifaçısı
                            </h2>
                            <div class="post-text">
                              Fəxri Xiyabanda onun adını daşıyan soyuq, qara
                              mərmər daşın doğuluşundan 24 ildən çox vaxt ötür.
                              Yoxluğundan keçən bu illər ərzində həyatda çox
                              şeylər dəyişib.
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="posts-r">
                      <div class="post">
                        <a href="/">
                          <div class="post-info">
                            <h2 class="post-title">
                              Tarixdə yeganə Qacar-Osmanlı müharibəsi
                            </h2>
                            <div class="post-text">
                              Türk-İslam aləminin son 6 əsrlik tarixinə nəzər
                              saldıqda, bu zaman aralığında qeyd olunan
                              coğrafiyada ən mühüm proseslərin mərkəzində
                              Osmanlı İmeperiyası ilə indiki Azərbaycan...
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="posts-r">
                      <div class="post">
                        <a href="/">
                          <div class="post-info">
                            <h2 class="post-title">
                              Segah muğamının ən mahir ifaçısı
                            </h2>
                            <div class="post-text">
                              Azərbaycan xalqı bütün muğamları sevdiyi kimi,
                              “Segah” muğamını da çox sevir və yüksək
                              dəyərləndirir. “Segah” muğamı xalqımızın milli
                              ruhuna, zövqünə daha da yaxındır.
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="posts-r">
                      <div class="post">
                        <a href="/">
                          <div class="post-info">
                            <h2 class="post-title">
                              Lev Trotski - Stalinin ən çox qorxduğu sirli
                              adamın dəhşəti
                            </h2>
                            <div class="post-text">
                              Son günlər keyfiyyətli film həvəskarları dərin
                              maraqla rus kinematoqraflarının ərsəyə
                              gətirdikləri “Trotski” serialını izləyirlər. Bu
                              ekran əsərində tanınmış inqilabçı Lev Trotski
                              ilə...
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- Posts end -->
                </div>
              </div>
            </div>
            <div class="sidebar">
              <div class="s-block" v-if="showCurrentRk == rkItems[6]">
                <div class="a-st a-display w-300x250">
                  <span class="a-text">300x250</span>
                  <div class="smrt-info">
                    <i class="smrt-l"></i>
                    <div class="smrt-wrap">
                      <div class="smrt-back"></div>
                      <div class="smrt-md">
                        <div class="smrt-m">
                          Ads by <img src="@/assets/images/smt.png" />
                        </div>
                        <div class="smrt-buttons">
                          <div class="smrt-button">
                            <a
                              class="smrt1"
                              href="https://advertiser.smartbee.az/az/register/"
                              target="_blank"
                              >Reklamçı ol</a
                            >
                          </div>
                          <div class="smrt-button">
                            <a
                              class="smrt2"
                              href="https://advertiser.smartbee.az/az/register/"
                              target="_blank"
                              >Yayınçı ol</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="smrt-text">
                        Reklamınızı <b>SmartBee</b> ilə effektiv edin.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="s-block" v-if="showCurrentRk == rkItems[11]">
                <iframe
                  src="https://beta.smartbee.az/banners/index300x250.php"
                  style="width: 300px; height: 250px; display: block"
                ></iframe>
              </div>
              <div class="s-block" v-if="showCurrentRk == rkItems[12]">
                <div class="a-st a-cursor a-display w-300x250" @click="openv">
                  <span class="a-text">Click to Fullscreen</span>
                </div>
              </div>
              <iframe
                src="https://beta.smartbee.az/banners/cubebanner.php"
                style="width: 300px; height: 250px; display: block"
                v-if="showCurrentRk == rkItems[13]"
              ></iframe>
              <!-- Posts start -->
              <div class="posts sd">
                <div
                  class="s-block"
                  style="text-align: center"
                  v-if="showCurrentRk == rkItems[15]"
                >
                  <iframe
                    frameborder="0"
                    style="height: 400px; width: 240px"
                    src="https://busy.az/banner-vacancies-page?dimension=3&bold_area_color=eeeeee&count=8&block_background=2a41e8&block_color=ffffff&is_prime=0&"
                  ></iframe>
                </div>
                <div
                  class="s-block"
                  style="text-align: center"
                  v-if="showCurrentRk == rkItems[16]"
                >
                  <iframe
                    src="https://smartbee.az/banners/Floating/300x250/index.html"
                    width="300"
                    height="250"
                  ></iframe>
                </div>
                <div
                  class="s-block"
                  style="text-align: center"
                  v-if="showCurrentRk == rkItems[19]"
                >
                  <iframe
                    src="https://smartbee.az/banners/Cylinder/300x250/index.html"
                    width="300"
                    height="250"
                  ></iframe>
                </div>
                <div
                  class="s-block"
                  style="text-align: center"
                  v-if="showCurrentRk == rkItems[20]"
                >
                  <iframe
                    src="https://smartbee.az/banners/Flip/300x250/index.html"
                    width="300"
                    height="250"
                  ></iframe>
                </div>
                <div
                  class="s-block"
                  style="text-align: center"
                  v-if="showCurrentRk == rkItems[21]"
                >
                  <iframe
                    src="https://smartbee.az/banners/Scatteredcube/index.html"
                    width="300"
                    height="250"
                  ></iframe>
                </div>

                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Lev Trotski - Stalinin ən çox qorxduğu sirli adamın
                          dəhşəti
                        </h2>
                        <div class="post-text">
                          Son günlər keyfiyyətli film həvəskarları dərin maraqla
                          rus kinematoqraflarının ərsəyə gətirdikləri “Trotski”
                          serialını izləyirlər. Bu ekran əsərində tanınmış
                          inqilabçı Lev Trotski ilə...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Distant təhsil nədir və xüsusiyyətləri
                        </h2>
                        <div class="post-text">
                          Müasir dövrdə texnologiyanın inkişafı təhsil sisteminə
                          də öz təsirini göstərir. Bunun nəticəsidir ki,
                          distant, yəni müəyyən məsafədən keçirilən təlim-tədris
                          sistemi yaranıb.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Klara Setkin-gənc yaşlarından ipə-sapa yatmayan qadın
                        </h2>
                        <div class="post-text">
                          1910-cu ildə Danimarkanın Kopenhagen şəhərində İlk
                          Beynəlxalq Qadınlar Yığıncağında (Beynəlxalq Sosialist
                          Qadınlar Konfransı) Almaniya İctimai Demokrat
                          Partiyası...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Jukovun diz üstə çök əmri və Beriyanın çöküşü
                        </h2>
                        <div class="post-text">
                          Stalinin ölümündən 1 saat 10 dəqiqə qabaq Sov.İKP MK
                          Rəyasət Heyətinin Büro iclası keçirildi və orada
                          təşkilati məsələlərə baxıldı. Qəbul edildi ki,
                          “Sov.İKP MK-nın iki orqanından...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="s-block" v-if="showCurrentRk == rkItems[6]">
                  <div class="a-st a-display w-240x400">
                    <span class="a-text">240x400</span>
                    <div class="smrt-info">
                      <i class="smrt-l"></i>
                      <div class="smrt-wrap">
                        <div class="smrt-back"></div>
                        <div class="smrt-md">
                          <div class="smrt-m">
                            Ads by
                            <img src="@/assets/images/smt.png" />
                          </div>
                          <div class="smrt-buttons">
                            <div class="smrt-button">
                              <a
                                class="smrt1"
                                href="https://advertiser.smartbee.az/az/register/"
                                target="_blank"
                                >Reklamçı ol</a
                              >
                            </div>
                            <div class="smrt-button">
                              <a
                                class="smrt2"
                                href="https://advertiser.smartbee.az/az/register/"
                                target="_blank"
                                >Yayınçı ol</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="smrt-text">
                          Reklamınızı <b>SmartBee</b> ilə effektiv edin.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          ABŞ-a işləyən, 15 min agenti satan sovet kəşfiyyatçısı
                        </h2>
                        <div class="post-text">
                          General-mayor Dmitri Fedoroviç Polyakov Sovet
                          kəşfiyyatı üçün çox işlər görmüşdü. O, SSRİ-nin ən
                          yüksək mükafatlarına sahib idi. Lakin onun barəsindəki
                          məhkəmə qərarı Polyakovun əsl...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Lev Trotski - Stalinin ən çox qorxduğu sirli adamın
                          dəhşəti
                        </h2>
                        <div class="post-text">
                          Son günlər keyfiyyətli film həvəskarları dərin maraqla
                          rus kinematoqraflarının ərsəyə gətirdikləri “Trotski”
                          serialını izləyirlər. Bu ekran əsərində tanınmış
                          inqilabçı Lev Trotski ilə...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Lev Trotski - Stalinin ən çox qorxduğu sirli adamın
                          dəhşəti
                        </h2>
                        <div class="post-text">
                          Son günlər keyfiyyətli film həvəskarları dərin maraqla
                          rus kinematoqraflarının ərsəyə gətirdikləri “Trotski”
                          serialını izləyirlər. Bu ekran əsərində tanınmış
                          inqilabçı Lev Trotski ilə...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="c-block" v-if="showCurrentRk == rkItems[6]">
                  <div class="a-st a-display w-300x600">
                    <span class="a-text">300x600</span>
                    <div class="smrt-info">
                      <i class="smrt-l"></i>
                      <div class="smrt-wrap">
                        <div class="smrt-back"></div>
                        <div class="smrt-md">
                          <div class="smrt-m">
                            Ads by
                            <img src="@/assets/images/smt.png" />
                          </div>
                          <div class="smrt-buttons">
                            <div class="smrt-button">
                              <a
                                class="smrt1"
                                href="https://advertiser.smartbee.az/az/register/"
                                target="_blank"
                                >Reklamçı ol</a
                              >
                            </div>
                            <div class="smrt-button">
                              <a
                                class="smrt2"
                                href="https://advertiser.smartbee.az/az/register/"
                                target="_blank"
                                >Yayınçı ol</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="smrt-text">
                          Reklamınızı <b>SmartBee</b> ilə effektiv edin.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="posts-r">
                  <div class="post">
                    <a href="/">
                      <div class="post-info">
                        <h2 class="post-title">
                          Lev Trotski - Stalinin ən çox qorxduğu sirli adamın
                          dəhşəti
                        </h2>
                        <div class="post-text">
                          Son günlər keyfiyyətli film həvəskarları dərin maraqla
                          rus kinematoqraflarının ərsəyə gətirdikləri “Trotski”
                          serialını izləyirlər. Bu ekran əsərində tanınmış
                          inqilabçı Lev Trotski ilə...
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <!-- Posts end -->
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="centered">
          <div class="footer-wrap">
            © Smartbee 2005 - 2020. All images © Flickr.com
          </div>
        </div>
      </footer>
      <div class="a-catf" v-if="showCurrentRk == rkItems[18]">
        <div class="a-catf-inner" style="width: 800px; height: 120px">
          <div class="catf-close" @click="closecat()"></div>
          <!-- <div class="a-text"> -->
          <!-- Extended catfish -->
          <app-none></app-none>
          <!-- </div> -->
        </div>
      </div>
      <div class="a-catf" v-if="showCurrentRk == rkItems[2]">
        <div class="a-catf-inner w-980x90 a-st">
          <div class="catf-close" @click="closecat()"></div>
          <div class="a-text">catfish</div>
        </div>
      </div>
      <div class="a-pp" v-if="showCurrentRk == rkItems[3]">
        <div class="pp-t">
          <div class="pp-z">
            <div class="w-380x302 a-st">
              <div class="catf-close" @click="closepop()"></div>
              <div class="a-text">popup</div>
            </div>
          </div>
        </div>
      </div>
      <div class="a-pp" v-if="showCurrentRk == rkItems[7]">
        <div class="f-pp">
          <div class="w-100 a-st">
            <div class="catf-close" @click="closepop"></div>
            <div class="x-sec">
              saytın açılmasına <span id="countdown">4</span> saniyə qalıb
            </div>
            <div class="a-text">fullscreen</div>
          </div>
        </div>
      </div>
      <div id="fullscreen">
        <div class="catf-close" @click="closenv"></div>
        <div class="pp-t">
          <div class="pp-z">
            <div class="p-video">
              <video id="p-vid" width="620" height="350" controls="">
                <source src="@/assets/images/p/huner.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="a-catf a-corner" v-if="showCurrentRk == rkItems[14]">
        <div class="a-catf-inner w-300x250 a-st">
          <div class="catf-close" @click="closecat"></div>
          <div class="a-text">Sticky vertical<br />corner</div>
        </div>
      </div>
    </div>

    <!-- inpage banner -->
    <!-- <script>
      var vid = document.getElementById("inpage");
      vid.onended = function () {
        this.remove();
      };
    </script> -->
    <!-- inpage banner -->
    <!-- pre-roll banner -->
    <!-- <script>
      var vid = document.getElementById("pre-roll");
      vid.onended = function () {
        this.remove();
        document.getElementById("skip").remove();
      };
      function closevid() {
        vid.remove();
        document.getElementById("skip").remove();
      }
    </script> -->
    <!-- pre-roll banner -->
    <!-- Catfish banner -->
    <!-- <script>
      function closecat() {
        var elem = document.getElementsByClassName("a-catf")[0];
        elem.parentNode.removeChild(elem);
      }
    </script> -->
    <!-- Catfish banner -->
    <!-- Pop-up banner -->
    <!-- <script>
      function closecat() {
        var elem = document.getElementsByClassName("a-pp")[0];
        elem.parentNode.removeChild(elem);
      }
    </script> -->
    <!-- Pop-up banner -->
    <!-- Collapce banner -->
    <!-- <script>
      var target = document.querySelector(".w-620x300");
      target.addEventListener("mouseenter", mOver, false);
      target.addEventListener("mouseleave", mOut, false);

      function mOver() {
        target.setAttribute("style", "height:120px;");
      }

      function mOut() {
        target.setAttribute("style", "height:300px;");
      }
    </script> -->
    <!-- Collapce banner -->
    <!-- display banner -->
    <!-- <script>
      let button = document.getElementsByClassName("smrt-l");
      let button2 = document.getElementsByClassName("smrt-back");
      console.log(document.getElementsByClassName("smrt-wrap")[0]);
      for (var i = 0; i < button.length; i++) {
        let tt = document.getElementsByClassName("smrt-wrap")[i];
        button[i].addEventListener("click", function () {
          tt.classList.add("open");
        });
        button2[i].addEventListener("click", function () {
          tt.classList.remove("open");
        });
      }
    </script> -->
    <!-- display banner -->
    <!-- fullscreen banner -->
    <!-- <script>
					function closecat() {
						var elem = document.getElementsByClassName("a-pp")[0];
						elem.parentNode.removeChild(elem);
					}
					var seconds = document.getElementById("countdown").textContent;
					var countdown = setInterval(function() {
						seconds--;
						document.getElementById("countdown").textContent = seconds;
						if (seconds == 0) closecat();
					}, 1000);
				</script> -->
    <!-- fullscreen banner -->
    <!-- interscroller banner -->
    <!-- <script>
      function parallax() {
        var container = document.getElementById("inscroll-banner");
        var scrollitem = document.getElementsByClassName(
          "inscroll-banner-item"
        )[0];
        var inb = document.getElementsByClassName("inb")[0];
        var inr = document.getElementsByClassName("inr")[0];

        //var parentWidth = document.getElementById("inscroll-banner").parentElement.clientWidth;
        var parentWidth = window
          .getComputedStyle(
            document.getElementById("inscroll-banner").parentElement,
            null
          )
          .getPropertyValue("width");
        container.style.width = parentWidth;
        scrollitem.style.width = parentWidth;
        scrollitem.style.height =
          Math.round(parseFloat(parentWidth) / 1.4) + "px";
        inb.style.width = parentWidth;
        inb.style.height = parseFloat(scrollitem.style.height) - 20 + "px";
        var zleft = container.getBoundingClientRect();
        inb.style.left = +zleft.left + "px";
        var imageWidth = 620;
        var imageHeight = 1042;

        var rat = parseFloat(parentWidth) / imageWidth;

        ratImage = rat * imageHeight;
        inr.style.width = parentWidth;
        inr.style.height = Math.round(ratImage) + "px";

        var vtop = window.innerHeight - Math.round(ratImage);
        inb.style.top = vtop + "px";

        var sbt = vtop;
        var cz = window.innerHeight / parseFloat(scrollitem.style.height);

        var dz =
          (container.offsetTop -
            window.innerHeight +
            parseFloat(inb.style.height) -
            container.offsetTop) /
          sbt;
        var gz = sbt / window.innerHeight;
        var ty = window.innerHeight;

        window.onscroll = function () {
          var xz =
            container.offsetTop -
            window.innerHeight +
            parseFloat(inb.style.height);
          var bz = container.offsetTop - window.innerHeight;

          if (
            container.offsetTop > document.documentElement.scrollTop &&
            document.documentElement.scrollTop > xz &&
            window.innerHeight > parseFloat(inb.style.height) * 1.5
          ) {
            var aa = container.offsetTop - document.documentElement.scrollTop;

            if (sbt < 0) {
              vtop = -aa / dz;
              if (vtop > 0) {
                vtop = 0;
              }
            } else if (sbt > 0) {
              vtop = -aa / dz;
              if (vtop < 0) {
                vtop = 0;
              }
            }
            inb.style.top = vtop + "px";
          } else if (
            container.offsetTop > document.documentElement.scrollTop &&
            document.documentElement.scrollTop > bz &&
            window.innerHeight < parseFloat(inb.style.height) * 1.5
          ) {
            ty = ty - 10;
            var nr = document.documentElement.scrollTop;
            var rr = container.offsetTop;
            rr = rr - nr;

            if (sbt < 0) {
              vtop = rr * gz;
              if (vtop > 0) {
                vtop = 0;
              }
            } else if (sbt > 0) {
              vtop = -rr * gz;
              if (vtop < 0) {
                vtop = 0;
              }
            }
            inb.style.top = vtop + "px";
          }
        };
      }

      parallax();
      window.onresize = function () {
        parallax();
      };
    </script> -->
    <!-- interscroller banner -->
    <!-- click-to-fullscreen banner -->
    <!-- <script>
      function closecat() {
        var elem = document.getElementById("fullscreen");
        var pvideo = document.getElementById("p-vid");
        pvideo.pause();
        elem.style.display = "none";
      }
      function openv() {
        var elem = document.getElementById("fullscreen");
        var pvideo = document.getElementById("p-vid");
        elem.style.display = "block";
        pvideo.play();
      }
    </script> -->
    <!-- click-to-fullscreen banner -->
  </div>
</template>
<script>
import axios from "axios";
import Nonstandart from "@/components/AddFormats/nonestandart.vue";
export default {
  name: "AddFormat",
  components: {
    appNone: Nonstandart,
  },
  data() {
    return {
      rkItemsVal: "",
      rkItems: [],
      formatSlugCount: [],
      metaTitle: {
        az: "SmartBee ",
        en: "SmartBee",
      },
      metaContent: {
        description: {
          az: "Azərbaycanın ən böyük veb-platforması",
          en: "The biggest web-platform in Azerbaijan. Ad network with programmatic solutions",
        },
        keywords: {
          az: "reklam, banner",
          en: "advertiser, banners",
        },
      },
      // rkItems: [
      //1   "pre-roll",
      //2   "inpage",
      //3   "catfish",
      //4   "pop-up",
      //5   "background",
      //6   "collapsible",
      //7   "displaybanner",
      //8   "fullscreen",
      //9   "newsblock",
      //10   "expandable",
      //11   "interscroller",
      //12   "e-commerce",
      //13   "clicktofullscreen",
      //14   "cubebanner",
      //15   "stickyverticalcorner",
      //16   "slider",
      //17   "floating",
      //18   "in-feedslider",
      // ],
    };
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: "description",
          content: this.metaContent.description[this.$i18n.locale],
        },
        { name: "keywords", content: "smartbee,formats,reklam" },
        { property: "og:locale", content: this.$i18n.locale },
      ],
    };
  },
  mounted() {
    // console.log(this.rkItems + "__this.rkItems_mnt");
  },
  updated() {
    var rkUrl = window.location.href.toString().split("/")[5];
    this.$store.commit("showCurrentRk", rkUrl);

    if (this.rkItems.includes(rkUrl) == true) {
    } else {
      window.location.href = "/error";
    }

    if (rkUrl == this.rkItems[6]) {
      let button = document.getElementsByClassName("smrt-l");
      let button2 = document.getElementsByClassName("smrt-back");
      // console.log(document.getElementsByClassName("smrt-wrap")[0]);
      for (var i = 0; i < button.length; i++) {
        let tt = document.getElementsByClassName("smrt-wrap")[i];
        button[i].addEventListener("click", function () {
          tt.classList.add("open");
        });
        button2[i].addEventListener("click", function () {
          tt.classList.remove("open");
        });
      }
    } else if (rkUrl == this.rkItems[7]) {
      function closecat() {
        var elem = document.getElementsByClassName("a-pp")[0];
        elem.parentNode.removeChild(elem);
      }
      var seconds = document.getElementById("countdown").textContent;
      var countdown = setInterval(function () {
        seconds--;
        document.getElementById("countdown").textContent = seconds;
        if (seconds == 0) closecat();
      }, 1000);
    } else if (rkUrl == this.rkItems[10]) {
      function parallax() {
        var container = document.getElementById("inscroll-banner");
        var scrollitem = document.getElementsByClassName(
          "inscroll-banner-item"
        )[0];
        var inb = document.getElementsByClassName("inb")[0];
        var inr = document.getElementsByClassName("inr")[0];

        var parentWidth =
          document.getElementById("inscroll-banner").parentElement.clientWidth;
        var parentWidth = window
          .getComputedStyle(
            document.getElementById("inscroll-banner").parentElement,
            null
          )
          .getPropertyValue("width");
        container.style.width = parentWidth;
        scrollitem.style.width = parentWidth;
        scrollitem.style.height =
          Math.round(parseFloat(parentWidth) / 1.4) + "px";
        inb.style.width = parentWidth;
        inb.style.height = parseFloat(scrollitem.style.height) - 20 + "px";
        var zleft = container.getBoundingClientRect();
        inb.style.left = +zleft.left + "px";
        var imageWidth = 620;
        var imageHeight = 1042;

        var rat = parseFloat(parentWidth) / imageWidth;

        ratImage = rat * imageHeight;
        inr.style.width = parentWidth;
        inr.style.height = Math.round(ratImage) + "px";

        var vtop = window.innerHeight - Math.round(ratImage);
        inb.style.top = vtop + "px";

        var sbt = vtop;
        var cz = window.innerHeight / parseFloat(scrollitem.style.height);

        var dz =
          (container.offsetTop -
            window.innerHeight +
            parseFloat(inb.style.height) -
            container.offsetTop) /
          sbt;
        var gz = sbt / window.innerHeight;
        var ty = window.innerHeight;

        window.onscroll = function () {
          var xz =
            container.offsetTop -
            window.innerHeight +
            parseFloat(inb.style.height);
          var bz = container.offsetTop - window.innerHeight;

          if (
            container.offsetTop > document.documentElement.scrollTop &&
            document.documentElement.scrollTop > xz &&
            window.innerHeight > parseFloat(inb.style.height) * 1.5
          ) {
            var aa = container.offsetTop - document.documentElement.scrollTop;

            if (sbt < 0) {
              vtop = -aa / dz;
              if (vtop > 0) {
                vtop = 0;
              }
            } else if (sbt > 0) {
              vtop = -aa / dz;
              if (vtop < 0) {
                vtop = 0;
              }
            }
            inb.style.top = vtop + "px";
          } else if (
            container.offsetTop > document.documentElement.scrollTop &&
            document.documentElement.scrollTop > bz &&
            window.innerHeight < parseFloat(inb.style.height) * 1.5
          ) {
            ty = ty - 10;
            var nr = document.documentElement.scrollTop;
            var rr = container.offsetTop;
            rr = rr - nr;

            if (sbt < 0) {
              vtop = rr * gz;
              if (vtop > 0) {
                vtop = 0;
              }
            } else if (sbt > 0) {
              vtop = -rr * gz;
              if (vtop < 0) {
                vtop = 0;
              }
            }
            inb.style.top = vtop + "px";
          }
        };
      }
      parallax();
      window.onresize = function () {
        parallax();
      };
    }
  },
  created() {
    var rkUrl = window.location.href.toString().split("/")[5];
    this.$store.commit("showCurrentRk", rkUrl);

    let tl = this.langTitle;
    let updateTitle = (this.metaTitle[tl] = "SmartBee | " + rkUrl);

    document.title = updateTitle;

    axios
      .post("https://api2.smartbee.az/api/formats?token=abc")
      .then((response) => {
        this.formatSlugCount = response.data.data.formats;
        console.log(this.formatSlugCount);
        for (let index = 0; index < this.formatSlugCount.length; index++) {
          this.rkItemsVal = response.data.data.formats[index].slug
            .replace(/\s/g, "")
            .toString()
            .toLowerCase();
          this.rkItems.push(this.rkItemsVal);
        }
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
    console.log(this.rkItems);
  },
  computed: {
    showCurrentRk() {
      return this.$store.getters.getRkVal;
    },
  },
  methods: {
    closecat() {
      var elem = document.getElementsByClassName("a-catf")[0];
      elem.parentNode.removeChild(elem);
    },
    closepop() {
      var elem = document.getElementsByClassName("a-pp")[0];
      elem.parentNode.removeChild(elem);
    },
    mousEnter() {
      var target = document.querySelector(".w-620x300");
      target.addEventListener("mouseenter", mOver, false);
      target.addEventListener("mouseleave", mOut, false);

      function mOver() {
        target.setAttribute("style", "height:120px;");
      }

      function mOut() {
        target.setAttribute("style", "height:300px;");
      }
    },
    skipVid() {
      var vid = document.getElementById("pre-roll");
      vid.onended = function () {
        this.remove();
        document.getElementById("skip").remove();
      };
    },
    closevid() {
      var vid = document.getElementById("pre-roll");
      vid.remove();
      document.getElementById("skip").remove();
    },
    openv() {
      var elem = document.getElementById("fullscreen");
      var pvideo = document.getElementById("p-vid");
      elem.style.display = "block";
      pvideo.play();
    },
    closenv() {
      var elem = document.getElementById("fullscreen");
      var pvideo = document.getElementById("p-vid");
      pvideo.pause();
      elem.style.display = "none";
    },
  },
};
</script>
<style lang="css" scoped src="@/assets/css/styleNew.css"></style>
<style scoped>
.smrt-a a,
.smrt-a img {
  display: block;
  width: 100%;
}
.smrt-info .smrt-l {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 99999;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-image: url(@/assets/images/info.svg?v1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.smrt-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  border: 1px solid #e9e9e9;
}
.smrt-wrap.open {
  visibility: visible;
  opacity: 1;
}
.smrt-wrap .smrt-back {
  position: absolute;
  top: 2px;
  left: 2px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-image: url(@/assets/images/back.svg);
  z-index: 100;
}
.smrt-md {
  position: absolute;
  height: 66px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}
.smrt-m {
  font-size: 16px;
  line-height: 20px;
  color: #a1a1a1;
  margin-bottom: 10px;
  height: 20px;
  text-align: center;
}
.smrt-m img {
  margin: 2px 0 2px 2px;
  vertical-align: top;
  display: inline-block;
}
.smrt-buttons {
  overflow: hidden;
  width: 270px;
  margin: 0 auto;
}
.smrt-buttons .smrt-button {
  width: 50%;
  padding: 0 5px;
  float: left;
}
.smrt-button a {
  display: block;
  text-decoration: none;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 10px;
  font-weight: 500;
}
.smrt-button a.smrt1 {
  background-color: #f04b23;
  color: #fff;
}
.smrt-button a.smrt2 {
  background-color: #f4f4f4;
  color: #565656;
}
.smrt-text {
  font-size: 13px;
  line-height: 16px;
  color: #7d7d7d;
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.w-728x90 .smrt-md {
  height: 36px;
}
.w-728x90 .smrt-m {
  float: left;
  margin: 8px 18px 8px 36px;
}
.w-728x90 .smrt-buttons {
  float: left;
}
.w-728x90 .smrt-text {
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: right;
  height: 16px;
  padding-right: 20px;
  width: 250px;
  right: 0;
}
.w-240x400 .smrt-buttons {
  width: 220px;
}
.w-468x60 .smrt-m {
  text-align: left;
  margin-bottom: 3px;
}
.w-468x60 .smrt-buttons {
  margin: 0;
}
.w-468x60 .smrt-buttons .smrt-button {
  padding: 0 10px 0 0;
}
.w-468x60 .smrt-text {
  font-size: 12px;
}
.w-468x60 .smrt-button a {
  padding: 3px 10px;
}
.w-468x60 .smrt-md {
  height: 47px;
  padding-left: 40px;
}
.w-468x60 .smrt-text {
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: right;
  height: 32px;
  padding-right: 20px;
  width: 150px;
  right: 0;
}
.w-160x600 .smrt-md,
.w-120x600 .smrt-md {
  height: 127px;
}
.w-160x600 .smrt-m,
.w-120x600 .smrt-m {
  width: 90px;
  height: 40px;
  margin: 0 auto 10px;
}
.w-160x600 .smrt-text,
.w-120x600 .smrt-text {
  padding: 0 14px;
}
.w-160x600 .smrt-buttons {
  width: 134px;
}
.w-160x600 .smrt-buttons .smrt-button,
.w-120x600 .smrt-buttons .smrt-button {
  float: none;
  width: 100%;
}
.w-160x600 .smrt-buttons .smrt-button:first-child,
.w-120x600 .smrt-buttons .smrt-button:first-child {
  margin-bottom: 5px;
}
.w-120x600 .smrt-buttons {
  width: 110px;
}
.w-120x600 .a-text {
  font-size: 28px;
}
.footer {
  background: #fff;
}
</style>