<template>
  <div class="home">
    <div class="container">
      <div class="flex-center position-ref full-height">
        <div class="code">404</div>
        <div class="message" style="padding: 10px">Not Found</div>
      </div>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src

export default {
  name: "PageNotFound",
  mounted() {
    this.$store.commit("changeComp", 2);
  },
};
</script>

<style scoped>
html,
body {
  background-color: #fff;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.full-height {
  height: 100vh;
  color: #636b6f;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.code {
  border-right: 2px solid;
  font-size: 26px;
  padding: 0 15px 0 15px;
  text-align: center;
}

.message {
  font-size: 18px;
  text-align: center;
}
</style>
  