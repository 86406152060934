import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import store from './store'
import i18n from "./i18n"
import AOS from 'aos'
import 'aos/dist/aos.css'
// export const eventBus = new Vue()

Vue.config.productionTip = false
Vue.use(VueMeta, {
  keyName: 'head'
})

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if(!language) {
    // language = 'az'
  }
  else {
    if(language == "az" || language == "en") { } else {
      language = "az"
      setTimeout(() => {
        next(' /error')
      }, 100)

      // next({
      //   path: '/login',
      //   // query: {
      //   //   nextUrl: to.fullPath,
      //   // }
      // })
      // return { path: "/" }
      // console.log(language)
    }
    // set the current language for i18n.
    i18n.locale = language
    next()
  }
  // document.title = to.meta.title

});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
