import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}`
    },
    {
      path: '/:lang',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          routId: 0,
          path: '',
          alias: 'home',
          name: ' Ana Səhifə',
          component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
        },
        {
          routId: 1,
          path: 'about-us',
          name: 'Haqqımızda',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
        },
        {
          routId: 2,
          path: 'advertisers',
          name: 'Reklamverənlər',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "advertisers" */ '../views/AdvertisersView.vue')
        },
        {
          routId: 3,
          path: 'publishers',
          name: 'Yayınçılar',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "publishers" */ '../views/PublishersView.vue')
        },
        {
          routId: 4,
          path: 'faq',
          name: 'Dəstək',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "Faq" */ '../views/FaqView.vue'),
        },
        {
          routId: 5,
          path: 'faq_Advetisers',
          name: 'Reklamverən',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.FaqAdvertiserView
          component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqAdvertiserView.vue')
        },
        {
          routId: 6,
          path: 'faq_Publishers',
          name: 'Yayınçı',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited. FaqPublisherView
          component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqPublisherView.vue')
        },
        {
          routId: 6,
          path: 'ad-formats',
          name: 'Reklam Formatları',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqFormatsView.vue')
        },
        {
          routId: 7,
          path: 'faqdocumentation',
          name: 'Dokumentasiya',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqDocumentationView.vue'),
          beforeEnter() { location.href = 'https://docs.smartbee.az/' }
        },
        {
          routId: 8,
          path: 'contact',
          name: 'Əlaqə',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
        },
        {
          // routId: 15,
          path: ':id',
          // name: {
          //   "az": {
          //     "menu": "Static"
          //   },
          //   "en": {
          //     "menu": "Static"
          //   }
          // },
          // route level code-splitting
          // this generates a separate chunk (pages.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
        },
        {
          // routId: 15,
          path: 'ad-formats/:id',
          // name: {
          //   "az": {
          //     "menu": "Static"
          //   },
          //   "en": {
          //     "menu": "Static"
          //   }
          // },
          // route level code-splitting
          // this generates a separate chunk (pages.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "pages" */ '../views/AddFormatsView.vue'),
        },
        {
          // routId: 9,
          path: "*",
          // name: 'PageNotFound',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "contact" */ '../views/PageNotFoundView.vue')
        },
        // {
        //   path: '*',
        //   beforeEnter: (to, from, next) => {
        //     next('/404')
        //   }
        // },
      ]
    },
  ]
});

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router
