import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showcomponent: 0,
    showfooter: false,
    getRkUrl: "",
    getStaticUrl: "",
    reloadVal: "",
  },
  getters: {
    getChangeVal(state) {
      return state.showcomponent
    },
    getFooterVal(state) {
      return state.showfooter
    },
    getRkVal(state) {
      return state.getRkUrl
    },
    getStaticVal(state) {
      return state.getStaticUrl
    },
    getReloadVal(state) {
      return state.reloadVal
    }
  },
  mutations: {
    changeComp(state, value) {
      state.showcomponent = value
    },
    showUnder(state, value) {
      state.showfooter = value
    },
    showCurrentRk(state, value) {
      state.getRkUrl = value
    },
    showCurrentStatic(state, value) {
      state.getStaticUrl = value
    },
    reloadPage(state, value) {
      state.reloadVal = value
    }
  },
  actions: {
  },
  modules: {
  }

})
